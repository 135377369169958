<template lang='pug'>
	div
</template>

<script>
import { mapActions } from 'vuex';

export default {
	created () {
		this.logout();
		setTimeout(() => {
			this.$router.replace({name: 'login'})
		}, 33);
	},
	methods: {
		...mapActions({
			logout : 'auth/logout',
		})
	}
};
</script>
